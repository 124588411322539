import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { generateFiscalYearOptions } from "../../../../utils/helpers/genereateDateRange";
import { BASE_URL } from "../../../../constants/api";
import axios from "axios";
import UserTarget from "./UserTarget";
import { BarGraph } from "./Line";
import { motion } from "framer-motion";
import ClipLoader from "react-spinners/ClipLoader";
import { useInView } from 'react-intersection-observer';

const ProfileAnalysis = () => {
  const user = useSelector((state) => state.user.userData?.userData);
  const [selectedDateRange, setSelectedDateRange] = useState(
    generateFiscalYearOptions().slice(-1)[0].value
  );
  const userId = useSelector((state) => state.user?.userData?.userData?._id);
  const token = useSelector((store) => store.auth.token?.token) || "";

  const [placements, setPlacements] = useState({});
  const [offeredPlacements, setOfferedPlacements] = useState({});
  const [loading, setLoading] = useState(true);

  const { ref: graphRef, inView: graphInView } = useInView({ triggerOnce: true, threshold: 0.1 });

  useEffect(() => {
    const fetchData = async () => {
      const [startDate, endDate] = selectedDateRange.split(",");

      try {
        const [placementsResponse, offeredPlacementsResponse] = await Promise.all([
          axios.post(`${BASE_URL}/get-Placements-by-month/${userId}`, { startDate, endDate }, { headers: { Authorization: `Bearer ${token}` } }),
          axios.post(`${BASE_URL}/get-offered-placement/${userId}`, { startDate, endDate }, { headers: { Authorization: `Bearer ${token}` } })
        ]);
        setPlacements(placementsResponse.data);
        setOfferedPlacements(offeredPlacementsResponse.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userId, selectedDateRange, token]);

  const handleDateRangeChange = (event) => {
    setSelectedDateRange(event.target.value);
  };

  const renderGraphData = (data, label, backgroundColor, borderColor) => {
    const startMonthIndex = 3;
    const months = Array.from({ length: 12 }, (_, i) =>
      new Date(null, ((startMonthIndex + i) % 12) + 1, 0).toLocaleString("default", { month: "short" })
    );

    if (!data) {
      return {
        labels: months,
        datasets: [],
      };
    }

    const formattedData = months.map((month, index) => {
      const actualIndex = (startMonthIndex + index) % 12;
      if (data.placements && data.placements[actualIndex]) {
        return data.placements[actualIndex].length || 0;
      } else {
        return 0;
      }
    });

    return {
      labels: months,
      datasets: [
        {
          label,
          data: formattedData,
          fill: false,
          backgroundColor,
          borderColor,
          borderWidth: 1,
          tension: 0.4,
        },
      ],
    };
  };

  const renderFeeGraphData = (data, label, backgroundColor, borderColor) => {
    const startMonthIndex = 3;
    const months = Array.from({ length: 12 }, (_, i) =>
      new Date(null, ((startMonthIndex + i) % 12) + 1, 0).toLocaleString("default", { month: "short" })
    );

    if (!data) {
      return {
        labels: months,
        datasets: [],
      };
    }

    const formattedData = months.map((month, index) => {
      const actualIndex = (startMonthIndex + index) % 12;
      return data.fee?.[actualIndex] || 0;
    });

    return {
      labels: months,
      datasets: [
        {
          label,
          data: formattedData,
          fill: false,
          backgroundColor,
          borderColor,
          borderWidth: 1,
          tension: 0.4,
        },
      ],
    };
  };

  const graphs = [
    {
      title: "Month-Wise Placements",
      data: renderGraphData(placements, "Placements", "rgb(255, 255, 0)", "rgba(75, 192, 192, 1)"),
    },
    {
      title: "Month-Wise Offered Placements",
      data: renderGraphData(offeredPlacements, "Offered Placements", "rgb(255, 255, 0)", "rgba(54, 162, 235, 1)"),
    },
    {
      title: "Month-Wise Placements Fee",
      data: renderFeeGraphData(placements, "Placement Fee", "rgb(255, 165, 0)", "rgba(255, 99, 132, 1)"),
    },
    {
      title: "Month-Wise Offered Placements Fee",
      data: renderFeeGraphData(offeredPlacements, "Offered Placement Fee", "rgb(255, 165, 0)", "rgba(153, 102, 255, 1)"),
    },
  ];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="container mx-auto px-4 pb-2 bg-gray-900 min-h-screen"
    >
      <div className="flex flex-col md:flex-row justify-between items-center mt-8 p-6 bg-[#436F6E] rounded-lg shadow-lg">
        <div className="text-center md:text-left">
          <h1 className="text-3xl font-bold text-white">Welcome, {user?.name}</h1>
          <p className="mt-2 text-sm text-gray-200">{user?.email}</p>
          <p className="text-sm text-gray-200">CID: {user?.cid}</p>
          <p className="text-sm text-gray-200">Reporting To: {user?.reporting[0]?.reportingTo?.name}</p>
        </div>
        <div className="mt-4 md:mt-0">
          <select
            value={selectedDateRange}
            onChange={handleDateRangeChange}
            className="px-4 py-2 rounded bg-[#3e6160] text-white focus:outline-none focus:ring-2 focus:ring-gray-100 hover:bg-[#5c7c7b]"
          >
            <option value="">Choose Year</option>
            {generateFiscalYearOptions().map((option) => (
              <option key={option.value} value={option.value}>{option.label}</option>
            ))}
          </select>
        </div>
      </div>

      {loading ? (
        <div className="flex justify-center items-center h-64">
          <ClipLoader color={"#ffffff"} loading={loading} size={50} />
        </div>
      ) : (
        <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-8" ref={graphRef}>
          {graphs.map((graph, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: -50 }}
              animate={graphInView ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="bg-[#436F6E] p-6 rounded-lg shadow-lg hover:shadow-2xl"
            >
              <h2 className="text-xl font-bold text-white mb-4">{graph.title}</h2>
              <BarGraph data={graph.data} />
            </motion.div>
          ))}
        </div>
      )}

      {/* <div className="mt-8 p-6 w-full bg-[#436F6E] rounded-lg shadow-lg flex justify-center hover:shadow-2xl">
        <UserTarget selectedDate={selectedDateRange} />
      </div> */}
    </motion.div>
  );
};

export default ProfileAnalysis;
