import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../../../constants/api";
import { useSelector } from "react-redux";
import { formatDateForInput } from "../../../../../utils/helpers/formatDateforInput";
import showToast from "../../../../../utils/helpers/showToast";
import Loader from "../../../../Loader";

const ChnageJoiningDate = ({ cid }) => {
  const token = useSelector((store) => store.auth?.token?.token);
  const [joiningDate, setJoiningDate] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getJoiningDate();
  }, []);

  const getJoiningDate = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/get-joining-date/${cid}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setJoiningDate(response.data.joiningDate);
    } catch (err) {
      console.log(err);
    }
  };


 const handleUpdate = async () =>{
    try {
        setLoading(true);
     const response = await axios.put(`${BASE_URL}/update-joining-date/${cid}`, {
        joiningDate:joiningDate
     }, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
     })    

     if(response.status===200){
        showToast("Joining Date Updated Successfully!", {
            duration: 3000,
            position: 'top-center', 
            style: {
              border: '1px solid ',
              padding: '4px',
              color: 'white',
              background: '#FF0000',
              
            },
          })
     }
     setLoading(false);
    } catch (err) {
        setLoading(false)
        showToast(err.data.message, {
            duration: 3000,
            position: 'top-center', 
            style: {
              border: '1px solid ',
              padding: '4px',
              color: 'white',
              background: '#FF0000',
              
            },
          })

        console.log(err);
    }

 }
 
 if (loading) {
    return <Loader/>;
  }
  return (
    <>
      <div className="flex relative w-full  justify-center border-2 rounded">
        <div className="  mx-8 my-8  w-2/4 relative h-64  cursor-pointer transition duration-300 ease-in-out transform hover:scale-105">
        <h1 className=" text-center w-full font-bold text-xl my-6">Change Joining Date</h1>

          <div className="flex mt-2">
            
          <div className="flex gap-2 mt-2">
                <span className=" mx-6 mt-2  text-lg  font-medium block uppercase">
                  Joining Date
                </span>
                <input
                  type="date"
                  className=" text-black  border p-2 uppercase rounded ml-10"
                  value={formatDateForInput(joiningDate)}
                  onChange={(e) => {
                    setJoiningDate(e.target.value);
                  }}
                />
              </div>

              <button
                className="hover:bg-[#4D9981] bg-[#0A3A2A] bottom-0 right-0 absolute  outline outline-1 rounded-md w-auto text-white font-semibold p-2 m-2 "
                onClick={() => handleUpdate()}
              >
                Update
              </button>

          </div>
        </div>
      </div>
    </>
  );
};

export default ChnageJoiningDate;
