import { getIdToken, onAuthStateChanged, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../utils/firebase/firebase';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setUser } from '../../utils/redux/authSlice/authSlice';
import { useNavigate } from 'react-router-dom';
import useUserDetails from './useUserDetails';

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [confirmation, setConfirmation] = useState(null);
  const [isForgotPassword, setIsForgotPassword] = useState(false); // New state variable
  const navigate = useNavigate();
  const { getUserDetails } = useUserDetails();
  const [authUser, setAuthUser] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const token = await getIdToken(user);
          setAuthUser(user);
          dispatch(setUser({ token }));
          setupTokenRefresh();
          setConfirmation('Login successful!');
          navigate('/');
        } catch (error) {
          setError(error.message);
        }
      }
    });

    return () => unsubscribe();
  }, [dispatch, navigate]);

  const setupTokenRefresh = () => {
    const intervalId = setInterval(async () => {
      try {
        const token = await getIdToken(authUser); // Force refresh
        console.log('Token refreshed:', token);
        dispatch(setUser({ token }));
      } catch (error) {
        console.error('Error refreshing token:', error.message);
      }
    }, 500000); // Refresh every 5 minutes

    return () => clearInterval(intervalId);
  };

  const handleSignIn = async () => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const newToken = await getIdToken(userCredential.user);
      dispatch(setUser({ token: newToken }));
      setAuthUser(userCredential.user);
      localStorage.setItem('User', JSON.stringify(userCredential.user));
      navigate('/');
      setConfirmation('Login successful!');
      getUserDetails();
    } catch (error) {
      let errorMessage = 'An error occurred while signing in.';
      if (error.code === 'auth/invalid-email') {
        errorMessage = 'The email address is not valid.';
      } else if (error.code === 'auth/user-disabled') {
        errorMessage = 'This user has been disabled.';
      } else if (error.code === 'auth/user-not-found') {
        errorMessage = 'No user found with this email address.';
      } else if (error.code === 'auth/invalid-credential') {
        errorMessage = 'Incorrect Email or password';
      }
      setError(errorMessage);
    }
  };

  const handleForgotPassword = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      setConfirmation('Password reset email sent!');
      setError(null);
    } catch (error) {
      let errorMessage = 'An error occurred while sending the password reset email.';
      if (error.code === 'auth/invalid-email') {
        errorMessage = 'The email address is not valid.';
      } else if (error.code === 'auth/user-not-found') {
        errorMessage = 'No user found with this email address.';
      }
      setError(errorMessage);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="bg-white p-8 shadow-md rounded-md w-96">
        <h2 className="text-2xl font-semibold mb-4">{isForgotPassword ? 'Forgot Password' : 'Sign In'}</h2>
        {confirmation ? (
          <p className="text-green-500 mb-4">{confirmation}</p>
        ) : (
          <div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-600">Email</label>
              <input
                type="email"
                className="mt-1 p-2 w-full border rounded-md"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setError('');
                }}
              />
            </div>
            {!isForgotPassword && (
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-600">Password</label>
                <input
                  type="password"
                  className="mt-1 p-2 w-full border rounded-md"
                  placeholder="Enter your password"
                  value={password}
                  onChange={(e) => {
                    setError('');
                    setPassword(e.target.value);
                  }}
                />
              </div>
            )}
            {error && <p className="text-red-500 mb-4">{error}</p>}
            <button
              className="bg-black hover:bg-blue-400 text-white px-4 py-2 rounded-md w-full"
              onClick={isForgotPassword ? handleForgotPassword : handleSignIn}
            >
              {isForgotPassword ? 'Send Reset Email' : 'Sign In'}
            </button>
            <p className="text-sm text-gray-600 mt-4 cursor-pointer" onClick={() => setIsForgotPassword(!isForgotPassword)}>
              {isForgotPassword ? 'Back to Sign In' : 'Forgot Password?'}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default SignIn;
