import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, BarElement, Title, Tooltip, Legend } from "chart.js";
import { motion } from "framer-motion";

ChartJS.register(CategoryScale, LinearScale, PointElement, BarElement, Title, Tooltip, Legend);

export const BarGraph = ({ data }) => {
  const maxDataValue = Math.max(...data.datasets[0].data);
  const roundedMax = Math.ceil(maxDataValue / 2) * 4; 

  const options = {
    responsive: true,
    scales: {
      x: {
        ticks: {
          color: "#FFFFFF",
          font: {
            size: 10, 
          },
          maxRotation: 0, 
          minRotation: 0, 
        },
        grid: {
          display: false, 
        },
      },
      y: {
        ticks: {
          color: "#FFFFFF", 
          precision: 0, 
          stepSize: 1, 
        },
        min: 0, 
        max: roundedMax, 
        grid: {
          color: "rgba(255, 255, 255, 0.1)", 
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          color: "#FFFFFF", 
          font: {
            size: 14, 
          },
        },
      },
      tooltip: {
        backgroundColor: "#333333",
        titleColor: "#ffffff",
        bodyColor: "#ffffff",
        callbacks: {
          label: function(tooltipItem) {
            return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`;
          },
        },
      },
      
    },
    hover: {
      mode: 'nearest',
      intersect: true,
    },
    animation: {
      duration: 1000, 
      easing: 'easeOutBounce', 
    },
  };

  const dataset = data.datasets.map(dataset => ({
    ...dataset,
    barThickness: 30, 
  }));

  const modifiedData = { ...data, datasets: dataset };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Bar options={options} data={modifiedData} />
    </motion.div>
  );
};
