import React, { useState } from "react";
import { useSelector } from "react-redux";
import Loader from "../../Loader";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faUserCircle,
  faDatabase,
  faCog,
  faBullseye,
} from "@fortawesome/free-solid-svg-icons";
import AdminProfile from "./AdminProfile";
import ProfileAnalysis from "../modules/userDashboardModule/ProfileAnalysis";
import PlacementDashboard from "../Dashboard/PlacementDashboard";
import UserTarget from "../modules/userDashboardModule/UserTarget";

const UserProfile = () => {
  const user = useSelector((state) => state.user.userData?.userData);
  const [confirmation, setConfirmation] = useState(null);
  const [showUserData, setShowUserData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showPasswordChange, setShowPasswordChange] = useState(false);
  const [showPlacementTable, setShowPlacementTable] = useState(false);
  const [showTarget, setShowTarget] = useState(false);
  const isBlocked = useSelector(
    (state) => state?.user?.userData?.userData?.isBlocked
  );

  if (isBlocked) {
    return (
      <div className="w-full flex justify-center">
        <p className="text-red-500 text-xl font-semibold">
          You are not allowed to access this page. Please contact support for
          further details.
        </p>
      </div>
    );
  }

  return (
    <div className="w-full flex justify-center ">
      <motion.div
        className="flex flex-row w-full bg-gray-900 overflow-hidden shadow-xl"
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div className="bg-[#077655] w-56  flex flex-col justify-between sticky top-0 pt-10">
          <div className="w-full">
            <button
              className={`w-full text-left px-6 py-4 text-white font-semibold text-lg ${
                showUserData ? "bg-[#40826D]" : ""
              }`}
              onClick={() => {
                setShowPasswordChange(false);
                setShowUserData(true);
                setShowPlacementTable(false);
                setShowTarget(false);
              }}
            >
              <FontAwesomeIcon icon={faUserCircle} className="mr-3" />
              Profile
            </button>

            <button
              className={`w-full text-left px-6 py-4 text-white font-semibold text-lg ${
                showPlacementTable ? "bg-[#40826D]" : ""
              }`}
              onClick={() => {
                setShowPlacementTable(true);
                setShowPasswordChange(false);
                setShowUserData(false);
                setShowTarget(false);
              }}
            >
              <FontAwesomeIcon icon={faDatabase} className="mr-3" />
              Placements
            </button>

            <button
              className={`w-full text-left px-6 py-4 text-white font-semibold text-lg ${
                showTarget ? "bg-[#40826D]" : ""
              }`}
              onClick={() => {
                setShowTarget(true);
                setShowPlacementTable(false);
                setShowPasswordChange(false);
                setShowUserData(false);
              }}
            >
              <FontAwesomeIcon icon={faBullseye} className="mr-3" />
              Target
            </button>

            <button
              className={`w-full text-left px-6 py-4 text-white font-semibold text-lg ${
                showPasswordChange ? "bg-[#40826D]" : ""
              }`}
              onClick={() => {
                setShowPasswordChange(true);
                setShowPlacementTable(false);
                setShowUserData(false);
                setShowTarget(false);
              }}
            >
              <FontAwesomeIcon icon={faCog} className="mr-3" />
              Settings
            </button>
          </div>
        </div>

        <div className="flex-grow bg-gray-900 p-6">
          {loading && <Loader />}

          {showPasswordChange && <AdminProfile />}
          {showUserData && <ProfileAnalysis />}
          {showPlacementTable && <PlacementDashboard />}
          {showTarget && <UserTarget />}

          {confirmation && (
            <p className="text-green-500 mt-4">
              <FontAwesomeIcon icon={faCheck} className="mr-2" />
              {confirmation}
            </p>
          )}
        </div>
      </motion.div>
    </div>
  );
};

export default UserProfile;
